
const initial ={
    List:[],
    Current:null,
}


const reduceAddingModel = (state = initial, action) => {
    let newState = state;
    switch (action.type) {
        case "ADDING-MODEL-CURRENT":
            console.log("^^^^^^^^^^^^^^current adding model > ", action.payload);
            newState.Current = action.payload;
            return Object.assign({}, newState);
        case  "ADDING-MODEL-LIST":
            newState.List = action.payload;
            return Object.assign({}, newState);
        default:
            return state
    }
}

export default reduceAddingModel;
