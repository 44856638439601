
const
    LOGGED_IN="LOGGED_IN",
    SAVE_TOKEN="SAVE_TOKEN",
    SAVE_NAVIGATION_MODULE="SAVE_NAVIGATION_MODULE",
    SAVE_NAVIGATION_MENU="SAVE_NAVIGATION_MENU",
    SAVE_NAVIGATIONS="SAVE_NAVIGATIONS";

export{
    LOGGED_IN,
    SAVE_TOKEN,
    SAVE_NAVIGATION_MODULE,
    SAVE_NAVIGATION_MENU,
    SAVE_NAVIGATIONS
}




